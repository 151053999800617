import React, { PropsWithChildren } from 'react';
import Footer from './Footer';
import Hero from './Hero';
import StandardHeader from './StandardHeader';
import { PageIdType } from '../types';
import { Box } from '@mui/material';


type PageProps = {
    headerType: "hero" | "standard",
    page: PageIdType
}
export default function PageContainer({ children, headerType, page }: PropsWithChildren<PageProps>) {

    return (
        <Box sx={{ width: '100%', height:"100%", bgcolor:"primary.dark" }}>
            {headerType === "hero" && <Hero />}
            {headerType === "standard" && <StandardHeader page={page} />}

            {children}
            <Footer />
        </Box>
    );
}