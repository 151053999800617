import { Link, List, ListItem, Typography } from "@mui/material";
import { SectionParagraph } from "../types"

type Props = {
    id: string,
    color: "primary" | "secondary",
    paragraphs: SectionParagraph[]
}

export default function SectionParagraphList({ id, color, paragraphs }: Props) {

    if (paragraphs.length < 1) return <></>;
    const fontColor = color === "primary" ? "primary.light" : "secondary.dark";

    const renderList = (startIndex: number): [number, React.ReactNode] => {
        if (!paragraphs) return [startIndex + 1, <></>];


        const list: SectionParagraph[] = [];
        let index = startIndex;
        while (index < paragraphs.length && paragraphs[index].bullet && paragraphs[index].bullet === true) {
            list.push(paragraphs[index]);
            index++;
        }

        if (list.length === 0) return [startIndex + 1, <></>];



        return [index, (
            <List key={`list-${startIndex}`} sx={{ listStyleType: 'disc' }} >
                {list.map((para, ix) => {
                    const hasLink = para.link && para.link.length > 0;
   
                    return (
                        <ListItem
                            key={`list-item-${id}-${startIndex + ix}`}
                            sx={{ ml: 4, display: 'list-item', color: fontColor }}>

                            {hasLink && <Link sx = {{color: "navy", textDecoration : "underline"}} href={para.link}>{para.text}</Link>}
                            {!hasLink &&
                                <Typography
                                    key={`typo-list-item-${id}-${startIndex + ix}`}
                                    variant="body2"
                                    color={fontColor}
                                    sx={{ mb: 0 }}>
                                        {para.text}
                                </Typography>
                            }
                        </ListItem>
                    );
                })}
            </List>
        )];
    }

    let currentIndex = 0;
    let parts: React.ReactNode[] = [];

    while (currentIndex < paragraphs.length) {
        let para = paragraphs[currentIndex];

        if (para.bullet && para.bullet === true) {
            let [lastIndex, listPart] = renderList(currentIndex);
            parts.push(listPart);
            currentIndex = lastIndex;
        }
        else {
            const lines = para.text.split("\n");
            let lineNo = 0;
            for (var line of lines) {
                parts.push(
                    <Typography
                        key={`typo-normal-item-${id}-${currentIndex}-${lineNo}`}
                        variant="body1"
                        sx={{ ml: para.indent ? 4 : 0, mb: 1, fontStyle: para.italic ? "italic" : "normal" }}
                        color={color === "primary" ? "primary.light" : "secondary.dark"}>
                        {line}
                    </Typography>
                );
                lineNo++;
            }
            currentIndex++;
        }
    }
    return parts;

}