import React from 'react';
import SimpleSection from '../components/SimpleSection';
import { useNavigate } from 'react-router-dom';
import aboutImage from '../images/aboutImage.png'
import PageContainer from '../components/PageContainer';
import { homePageAboutContent as about } from '../content';

export default function HomePage() {

    const navigate = useNavigate();

    const handleAboutClick = () => {
        navigate("/about");
    }

    return (
        <PageContainer headerType="hero" page = "Home">
            <SimpleSection
                {...about}
                onButtonClick={handleAboutClick}
                imgUrl={aboutImage} />
        </PageContainer>
    )
}
