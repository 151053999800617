import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography';
import { Hidden } from '@mui/material';
import hero from '../images/herodark.png';
import TopBar from './TopBar';

function DropShadowTypography(props: TypographyProps) {
    return (
        <Typography {...props} sx={(theme) => ({
            color: `${theme.palette.primary.light}`,
            textAlign: "center",
            textShadow: `3px 3px 5px ${theme.palette.primary.contrastText}`
        })} />
    );
}

export default function Hero() {
    const [heroHeight, setHeroHeight] = useState<number>(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            const height = event[0].borderBoxSize[0].blockSize;
            setHeroHeight(height);
        });

        const containerDiv = document.getElementById("hero");
        if (containerDiv) resizeObserver.observe(containerDiv);
    });

    const renderCenteredText = () => {
        return (
            <Stack spacing={2} sx={{ width: "100%", height:"100%", justifyContent: 'center'}}>
                <DropShadowTypography variant="h2">
                    Cantastoria
                </DropShadowTypography>
                <Hidden smDown>
                    <DropShadowTypography variant="h6" >
                        Project-based chamber choir in Austin, TX.
                    </DropShadowTypography>
                    <DropShadowTypography variant="h6">
                        Multi-modal musical storytelling with a choral core.
                    </DropShadowTypography>
                </Hidden>
            </Stack>
        );
    }

    return (
        <Box
            id="hero"
            sx={{
                width: '100%',
                height: 0,
                pb: { xs: '48%', sm: '46%', md: '44%', lg: '42%', xl: '40%' },
                backgroundImage: `url(${hero})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
            <Box sx={{ width: '100%', height: { height: heroHeight }, display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ display: "flex", flexDirection: "column", width:"100%", height: heroHeight }}>
                    <Box sx = {{px:3}} flex={1}>
                        <TopBar textColor="primary.light" currentPage = "Home"/>
                    </Box>
                    <Box flex={3} sx = {{ width: '100%'}}>
                        {renderCenteredText()}
                    </Box>
                    <Box sx = {{minHeight:"25%"}} flex={1}>&nbsp;</Box>
                </Box>
            </Box>
        </Box>
    );
}