import React, { PropsWithChildren } from 'react';
import { Box, Typography, Button} from '@mui/material';
import { SectionParagraph } from '../types';
import SectionParagraphList from './SectionParagraphList';

type PageProps = {
    id: string,
    index?: number,
    title?: string,
    bodyText?: SectionParagraph[],
    buttonText?: string,
    imgUrl?: string,
    onButtonClick?: () => void
}

const imgClipPath = "ellipse(50% 45% at 50% 55%)";

export default function SimpleSection({ id, index, title, bodyText, buttonText, onButtonClick, imgUrl, children }: PropsWithChildren<PageProps>) {
    const odd: boolean = (index ?? 0 % 2) === 1;


    const handleClick = () => {
        if (onButtonClick) onButtonClick();
    }

    return (
        <Box sx={{
            pt: { xs: 4, sm: 6, md: 8 },
            px: 6,
            display: 'flex',
            flexWrap: 'wrap',
            bgcolor: odd ? "secondary.main" : "primary.light"
        }}>
            <Box sx={{ mb: 4, width: { xs: '100%', md: imgUrl ? '50%' : "100%" } }}>
                {title && <Typography sx={{ mb: 2 }} variant="h2" color={odd ? "primary.light" : "secondary.dark"} >{title}</Typography>}
                {bodyText && <SectionParagraphList id = {`ss-${id}`} paragraphs={bodyText} color = {odd? "primary": "secondary"}/>}
                {buttonText &&
                    <Button
                        variant='contained'
                        color = {odd?"primary": "secondary"}
                        sx={{ 
                            mt: 2, mb: { xs: 0, md: 6 }, 
                            borderRadius: 20, 
                            textTransform: "none",
                            color: odd? "secondary.dark":"primary.light",
                            "&:hover" : {
                                color: "primary.light"
                            }
                            }}
                        onClick={handleClick}>
                        {buttonText}
                    </Button>
                }
                {children}
            </Box>
            {imgUrl &&
                <Box sx={{ mb: 4, width: { xs: '100%', md: imgUrl ? '50%' : "100%" } }}>
                    <Box sx={{ display: 'flex', justifyContent: "center" }}>
                        <Box
                            component="img"
                            src={imgUrl}
                            sx={{
                                mt: { xs: 0, mathDepth: 4 },
                                width: { xs: "75%", md: '80%' },
                                clipPath: imgClipPath,
                                filter: "saturate(0.5)"
                            }} />
                    </Box>
                </Box>
            }
        </Box>
    );

}