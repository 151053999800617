import { createTheme, responsiveFontSizes } from '@mui/material/styles';


const appPalette = {
  lightest: '#E1DFD9',   // off white
  light: '#D7D6C6',      // light yellowish beige
  accent: '#2B462F',   // forest green
  dark: '#201D14',    // dark grey/brown
  darkest: '#181818'        // near black
};

// const white = '#fff';
// Create a theme instance.

const baseTheme = createTheme({
  palette: {
    primary: {
      light: appPalette.lightest,
      main: appPalette.light,
      dark: appPalette.dark,
      contrastText: appPalette.darkest,
    },
    secondary: {
      light: appPalette.light,
      main: appPalette.accent,
      dark: appPalette.dark,
      contrastText: appPalette.darkest,
    },
  },
  typography: {
    h1: {fontWeight:900},
    h2: {fontWeight:900},
    h3: {fontWeight:900},
    h4: {fontWeight:750},
    h5: {fontWeight:600},
    h6: {fontWeight:500},
  }
});

const theme = responsiveFontSizes(baseTheme, {factor:4});
export default theme;