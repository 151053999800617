import { Box, Typography } from "@mui/material"

type Props = {
    title: string,
    text:string
};

export default function IntroHeader({title,text}:Props) {
    return (
        <Box sx={{ width: "100%", bgcolor: "primary.light", pb:3 }}>
            <Box sx={{ 
                    px: 6, pt: 4, pb: 8, 
                    display: 'flex', 
                    flexWrap: 'wrap',
                    clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0 100%)', 
                    bgcolor: "primary.dark" }}>
                <Box sx={{ mb:3, width: { xs: '100%', sm: "50%" } }}>
                    <Typography variant='h2' color="primary.light">{title}</Typography>
                </Box>
                <Box sx={{ width: { xs: '100%', sm: "50%" } }}>
                    <Typography variant='h5' color="primary.light">{text}</Typography>
                </Box>
            </Box>
        </Box>

    );
}