import { Box, Hidden, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { PageIdType } from '../types';
import { useState } from "react";

type PageProps = {
    textColor: string,
    currentPage: PageIdType
}

export default function TopBar({ textColor, currentPage }: PageProps) {
    const navigate = useNavigate();
    const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);

    const pageStyle = { mt: 1, mx: 1, fontWeight: 600, cursor: "pointer" };
    const currentPageStyle = {
        ...pageStyle,
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
    };


    const handleClick = (targetUrl: string) => () => {
        navigate(targetUrl);
    }

    const handleCloseMenuWithNavigate = (targetUrl: string) => () => {
        setAnchorMenu(null);
        if (targetUrl === "/home") targetUrl = "/";
        navigate(targetUrl);
    }


    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMenu(event.currentTarget);
    };

    const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMenu(null);
    }

    const renderMenuItem = (page: PageIdType) => {
        return (
            <MenuItem
                key={`menu-${page.toLowerCase()}`}
                onClick={handleCloseMenuWithNavigate(`/${page.toLowerCase()}`)}>
                <Typography textAlign="center">{page}</Typography>
            </MenuItem>
        );
    }

    const renderMenu = () => {
        return (
            <Menu
                sx={{ mt: '45px' }}
                id="menu"
                anchorEl={anchorMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorMenu)}
                onClose={handleCloseMenu}
            >
                {renderMenuItem("Home")}
                {renderMenuItem("Cantatas")}
                {renderMenuItem("Contact")}
                {renderMenuItem("About")}

            </Menu>

        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ mx: 2, display: 'flex', width: '100%', justifyContent: "space-between" }}>
                <Box id="topbar-left">
                    <Hidden mdDown>
                        <Typography variant="h5" color={textColor} onClick={handleClick("/")} sx={{ fontWeight: 800, cursor: "pointer" }}>Cantastoria</Typography>
                    </Hidden>
                </Box>
                <Box id="topbar-right">
                    <Hidden mdDown>
                        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                            <Typography variant="body2" color={textColor} onClick={handleClick("/cantatas")} sx={currentPage === "Cantatas" ? currentPageStyle : pageStyle}>Cantatas</Typography>
                            <Typography variant="body2" color={textColor} onClick={handleClick("/contact")} sx={currentPage === "Contact" ? currentPageStyle : pageStyle}>Contact</Typography>
                            <Typography variant="body2" color={textColor} onClick={handleClick("/about")} sx={currentPage === "About" ? currentPageStyle : pageStyle}>About</Typography>
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton onClick={handleOpenMenu} sx={{ p: 0 }}>
                            <MenuIcon sx={{ color: textColor }} />
                        </IconButton>
                        {renderMenu()}
                    </Hidden>
                </Box>
            </Box>
        </Box>

    );
}