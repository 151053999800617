import { Box } from "@mui/material";
import TopBar from "./TopBar";
import { PageIdType } from "../types";

type Props = {
    page: PageIdType
}

export default function StandardHeader({ page }: Props) {
    const bgcolor = page === 'Cantatas' || page === 'About' ? "primary.dark" : "primary.light";
    const color = page === 'Cantatas' || page === 'About' ? "primary.light" : "secondary.dark";
    return (
        <Box sx={{ width: "100%", bgcolor: bgcolor }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ px:3, display: "flex", height: '120px', flexDirection: "column", width: "100%" }}>
                    <TopBar textColor={color} currentPage={page} />
                </Box>
            </Box>
        </Box>
    );
}