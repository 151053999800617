import React from 'react';
import PageContainer from '../components/PageContainer';
import CantataSection from '../components/CantataSection';
import {cantatasContent as cantatas} from '../content';
import IntroHeader from '../components/IntroHeader';

const title = "Cantatas";
const text = `We work on a few cantata style projects concurrently at any given time.   Below is an overview of some that we are working on at the moment.`;

export default function CantatasPage() {

    return (
        <PageContainer headerType="standard" page = "Cantatas">
            <IntroHeader title = {title} text = {text}/>
            {cantatas.map((c,ix) => (
                <CantataSection 
                    key ={`cs-${ix}`}
                    index = {ix}
                    {...c}/>
            ))}
        </PageContainer>
    )
}
