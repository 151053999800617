import React, { MouseEvent } from 'react';
import PageContainer from '../components/PageContainer';
import SimpleSection from '../components/SimpleSection';
import contactImage from '../images/contactus.png';
import { Box, Button, Link, Stack, TextField, Typography } from '@mui/material';


const para1 = 'Have feedback for us?  Creative ideas?  Interest in collaborating?   Thinking about joining us?';
const includeForm = false;

export default function ContactPage() {

    const textFieldStyle = {
        mb: 2,
        width: "100%"
    }

    function handleClick(event: MouseEvent<HTMLButtonElement>): void {
        console.log("send clik");
    }


    const renderForm = () => (
        <>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <TextField sx={{ ...textFieldStyle, mr: 1 }} id="firstName" label="First Name" variant="outlined" />
                <TextField sx={textFieldStyle} id="lastName" label="Last Name" variant="outlined" />
            </Box>
            <TextField sx={textFieldStyle} required id="email" label="Email" variant="outlined" />
            <TextField sx={textFieldStyle} required multiline rows={5} id="message" label="Message" variant="outlined" />
            <Box>
                <Button sx={{ textTransform: "none", borderRadius: 20, color: "primary.light" }} color="secondary" variant="contained" onClick={handleClick}>Send</Button>
            </Box>
        </>
    );

    return (
        <PageContainer headerType="standard" page = "Contact">
            <SimpleSection
                id="contact"
                title="Contact Us"
                imgUrl={contactImage}
            >
                <Stack sx={{ mb: 3 }}>
                    <Typography sx={{ mb: 1 }} variant="body1" color="secondary.dark" >{para1}</Typography>
                    <Typography sx={{ mb: 2 }} variant="body1" color="secondary.dark" >
                        Email us at <Link color = "secondary.dark" href = "mailto: info@cantastoria.org">info@cantastoria.org</Link></Typography>
                    {includeForm && renderForm()}
                </Stack>
            </SimpleSection>
        </PageContainer>
    )
}
